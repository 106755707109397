import { render, staticRenderFns } from "./ExpandingList.vue?vue&type=template&id=046173cc&"
import script from "./ExpandingList.vue?vue&type=script&lang=js&"
export * from "./ExpandingList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nde/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('046173cc')) {
      api.createRecord('046173cc', component.options)
    } else {
      api.reload('046173cc', component.options)
    }
    module.hot.accept("./ExpandingList.vue?vue&type=template&id=046173cc&", function () {
      api.rerender('046173cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/ExpandingList/ExpandingList.vue"
export default component.exports